import { HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { catchError, switchMap } from 'rxjs';

import { environment } from '@/shared/environments/environment';

export const authInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
  const authService = inject(MsalService);
  const activeAccount = authService.instance.getActiveAccount() || undefined;

  const accessTokenRequest = {
    scopes: environment.msalConfig.apiScopes,
    account: activeAccount,
  };

  return authService.acquireTokenSilent(accessTokenRequest).pipe(
    catchError(() => authService.acquireTokenRedirect(accessTokenRequest)),
    switchMap((accessTokenResponse) => {
      const token = accessTokenResponse?.accessToken ?? '';
      const authReq = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${token}`),
      });
      return next(authReq);
    })
  );
};
