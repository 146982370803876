@if (isAppLoading() && !isAuthenticated() && !isAuthenticatedButNoAdmin()) {
  <div class="flex h-screen items-center justify-center bg-white">
    <nvx-ui-spinner />
  </div>
} @else {
  <div class="flex h-screen overflow-hidden bg-white">
    @if (isAuthenticated()) {
      <nvx-layout-sidebar class="shadow-md" (onLogout)="logout()" />
    }
    <div class="flex flex-1 flex-col overflow-hidden">
      <main class="flex-1 overflow-auto bg-white">
        <router-outlet />
      </main>
    </div>
  </div>
}
