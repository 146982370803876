import { ChangeDetectionStrategy, Component, computed, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { filter, Subject, takeUntil } from 'rxjs';

import { SideBarComponent } from '@/app/components/layout/sidebar/sidebar.component';
import { ForbiddenNoAdminComponent } from '@/app/pages/forbidden/forbidden-no-admin.component';
import { environment } from '@/environments/environment';
import { SpinnerComponent } from '@/shared/components';
import { LoggerService } from '@/shared/services';
import { UserStore } from '@/shared/stores';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterOutlet, SideBarComponent, SpinnerComponent, ForbiddenNoAdminComponent],
  selector: 'nvx-root',
  standalone: true,
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  authService = inject(MsalService);
  msalBroadcastService = inject(MsalBroadcastService);
  msalGuardConfig = inject<MsalGuardConfiguration>(MSAL_GUARD_CONFIG);
  userStore = inject(UserStore);
  router = inject(Router);
  logger = inject(LoggerService);

  isAuthenticatedButNoAdmin = signal(false);
  isAuthenticated = signal(false);
  isAppLoading = computed(() => this.userStore.isLoading());
  private readonly _destroying$ = new Subject<void>();

  ngOnInit(): void {
    this.authService.handleRedirectObservable().subscribe(
      () => {
        this.setIsAuthenticated();
      },
      (error) => {
        this.logger.error(error);
        this.userStore.setLoadingState(false);
        this.isAuthenticatedButNoAdmin.set(true);
        this.router.navigate(['/forbidden']).then();
      }
    );

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe(async (result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(payload.account);
        this.setIsAuthenticated();
        if (!environment.production) {
          await this.loadUserDetails();
        }
      });

    /* The following inProgress observable subscription allows the App to re-fetch the user details
    on every page refresh too, instead of just after login as we did in msalSubject observable subscription above.
    Only enable this in production mode to avoid App Refresh or HMR delay during development. */
    if (environment.production) {
      this.msalBroadcastService.inProgress$
        .pipe(
          filter((status: InteractionStatus) => status === InteractionStatus.None),
          takeUntil(this._destroying$)
        )
        .subscribe(async () => {
          if (this.isAuthenticated()) {
            await this.loadUserDetails();
          }
        });
    }
  }

  setIsAuthenticated() {
    this.isAuthenticated.set(this.authService.instance.getAllAccounts().length > 0);
  }

  login() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  logout() {
    this.authService.logoutRedirect();
  }

  async loadUserDetails() {
    this.userStore.setLoadingState(true);
    await this.userStore.loadCurrentUser();
    await this.userStore.loadMyUserProfile();
    this.userStore.setLoadingState(false);
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
