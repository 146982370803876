import {
  ChangeDetectionStrategy,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  inject,
  OnInit,
  output,
  signal,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';

import { UserStore } from '@/shared/stores';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ButtonModule, RouterModule, TooltipModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  selector: 'nvx-layout-sidebar',
  standalone: true,
  templateUrl: './sidebar.component.html',
})
export class SideBarComponent implements OnInit {
  userStore = inject(UserStore);
  isAuthenticatedButNoAdmin = signal(false);
  isSideNavCollapsed = signal(false);
  onLogout = output();

  ngOnInit(): void {
    this.isAuthenticatedButNoAdmin.set(!this.userStore.isAdmin());
  }

  toggleSideNav = () => {
    this.isSideNavCollapsed.set(!this.isSideNavCollapsed());
  };

  logout = () => {
    this.onLogout.emit();
  };

  navLinks = [
    {
      label: 'Home',
      icon: 'mdi:home-outline',
      to: '/home',
    },
    {
      label: 'Organizations',
      icon: 'clarity:organization-line',
      to: '/organizations',
    },
    {
      label: 'Shipments',
      icon: 'uil:ship',
      to: '/shipments',
    },
    {
      label: 'Tenants',
      icon: 'mdi:company',
      to: '/tenants',
    },
    {
      label: 'Role Management',
      icon: 'fluent-mdl2:permissions',
      to: '/role-management',
    },
  ];
}
