import { inject } from '@angular/core';
import { CanActivateFn, Router, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

import { UserStore } from '@/shared/stores';

const AdminRoleGuard: CanActivateFn = () => {
  const userStore = inject(UserStore);
  const router = inject(Router);
  if (userStore.isAdmin()) {
    return true;
  }
  router.navigate(['/']);
  return false;
};

export const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    loadComponent: () => import('@/app/pages/home/home.component').then((c) => c.HomeComponent),
    canActivate: [MsalGuard],
  },
  {
    path: 'organizations',
    loadComponent: () => import('@/app/pages/organization/organization.component').then((c) => c.OrganizationComponent),
    canActivate: [MsalGuard, AdminRoleGuard],
  },
  {
    path: 'organizations/:id',
    loadComponent: () =>
      import('@/app/pages/organization/organization-details.component').then((c) => c.OrganizationDetailsComponent),
    canActivate: [MsalGuard, AdminRoleGuard],
  },
  {
    path: 'notifications',
    loadComponent: () => import('@/app/pages/notifications.component').then((c) => c.NotificationsComponent),
    canActivate: [MsalGuard, AdminRoleGuard],
  },
  {
    path: 'shipments',
    loadComponent: () => import('@/app/pages/shipments/shipments.component').then((c) => c.shipmentsComponent),
    canActivate: [MsalGuard, AdminRoleGuard],
  },
  {
    path: 'tenants',
    loadComponent: () => import('@/app/pages/tenants/tenants.component').then((c) => c.TenantsComponent),
    canActivate: [MsalGuard, AdminRoleGuard],
  },
  {
    path: 'tenant-details/:id',
    loadComponent: () => import('@/app/pages/tenants/tenant-detail.component').then((c) => c.TenantDetailComponent),
    canActivate: [MsalGuard, AdminRoleGuard],
  },
  {
    path: 'role-management',
    loadComponent: () =>
      import('@/app/pages/role-management/role-management.component').then((c) => c.RoleManagementComponent),
    canActivate: [MsalGuard, AdminRoleGuard],
  },
  {
    path: 'forbidden',
    loadComponent: () =>
      import('@/app/pages/forbidden/forbidden-no-admin.component').then((c) => c.ForbiddenNoAdminComponent),
  },
  {
    path: '**',
    loadComponent: () => import('@/app/pages/error/error.component').then((c) => c.ErrorComponent),
    canActivate: [MsalGuard, AdminRoleGuard],
  },
];
