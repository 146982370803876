@if (isAuthenticatedButNoAdmin()) {
  <div class="flex h-screen items-center justify-center bg-white"></div>
} @else {
  <aside
    class="flex h-full flex-col divide-y transition-all duration-300 ease-in-out"
    [class]="isSideNavCollapsed() ? 'w-20' : 'w-64'"
  >
    <div class="relative flex min-h-16 items-center justify-center border-b border-primary-light bg-primary">
      <img
        alt="NVX"
        [class]="isSideNavCollapsed() ? 'h-4' : 'px-4 py-4'"
        [src]="isSideNavCollapsed() ? '/assets/logos/nvx_ac_logo_collapsed.png' : '/assets/logos/nvx_ac_logo_wide.png'"
      />
      <p-button
        class="absolute bottom-0 right-0 z-10 translate-x-1/2 translate-y-3 rounded-full bg-primary-light"
        styleClass="p-1 text-white"
        [rounded]="true"
        [text]="true"
        (onClick)="toggleSideNav()"
      >
        <iconify-icon icon="mdi:chevron-up" [class]="isSideNavCollapsed() ? 'rotate-90' : '-rotate-90'" />
      </p-button>
    </div>
    <div class="flex h-full flex-col justify-between border-none bg-primary">
      <ul class="space-y-2 px-2 py-4 font-medium">
        @for (link of navLinks; track link.label) {
          <li
            class="flex cursor-pointer items-center gap-3 rounded-md text-white hover:bg-primary-light"
            [class]="isSideNavCollapsed() ? 'justify-center px-2 py-3' : 'px-4 py-2'"
            [pTooltip]="isSideNavCollapsed() ? link.label : undefined"
            [routerLink]="link.to"
            [routerLinkActive]="'!bg-primary-light !font-medium'"
          >
            <iconify-icon class="text-2xl" [icon]="link.icon" />
            @if (!isSideNavCollapsed()) {
              <span>{{ link.label }}</span>
            }
          </li>
        }
      </ul>
      <p-button
        class="mx-2 my-4"
        styleClass="w-full rounded-none bg-primary-light border-none h-10 flex gap-2 items-center justify-center"
        [pTooltip]="isSideNavCollapsed() ? 'Logout' : undefined"
        (onClick)="logout()"
      >
        <iconify-icon class="text-2xl" icon="tabler:logout" />
        @if (!isSideNavCollapsed()) {
          <span>Logout</span>
        }
      </p-button>
    </div>
  </aside>
}
